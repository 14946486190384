import './App.css';

function App() {
  return (
    <div className="outer">
      <div className="inner">
        <h1>Braimler</h1>
        <h2>Web Application Development</h2>
      </div>
    </div>
  );
}

export default App;
